import * as Bootstrap from "bootstrap-italia";
import jquery from "jquery";
import Popper from "popper.js";

window.__PUBLIC_PATH__ = "/fonts";
window.Popper = Popper;
window.jQuery = jquery;
window.jquery = jquery;
window.bootstrap = Bootstrap;
window.$ = jquery;
